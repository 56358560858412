/*
 PC common
---------------------------------------- */

.sp-on {
  display: none;
}

.pc-on {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 400;
  color: $color;
  min-width: 1280px;
  text-align: justify;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.wrapper {
  width: 1140px;
  margin: auto;
}

header {
  padding-bottom: 14px;
  .seo_text {
    font-size: $fs-14;
    color: #fff;
    background-color: #171c61;
    margin-bottom: 13px;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    padding-left: 50px;
    img {
      width: 101px;
      max-width: 100%;
    }
  }
  nav {
    width: 900px;
    position: relative;
    ul {
      font-size: 0;
      li {
        display: inline-block;
        width: calc(98% / 6);
        a {
          font-size: 20px;
          color: #bfae73;
          text-decoration: none;
          display: block;
          text-align: center;
          padding: 40px 0 32px;
        }
      }
    }
    #slide-line {
      position: absolute;
      bottom: 0;
      height: 5px;
      background-color: #bfae73;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
    }
  }
}

footer {
  margin-top: 180px;
  border-top: 8px solid #171c61;
  border-bottom: 40px solid #171c61;
  padding-top: 30px;
  padding-bottom: 45px;
  .wrapper {
    width: 840px;
  }
  .sns_bnr {
    padding: 20px 0;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  nav {
    ul {
      display: flex;
      justify-content: space-between;
      li {
        padding: 50px 0 70px;
        a {
          font-size: 20px;
          color: #bfae73;
          text-decoration: none;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .f_logo {
    text-align: center;
    a {
      display: block;
      img {
        margin: auto;
      }
    }
  }
}

#page-top {
  position: fixed;
  bottom: 38px;
  right: 38px;
  a {
    text-decoration: none;
    width: 100px;
    display: block;
  }
}
