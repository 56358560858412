@charset "UTF-8";
/*
  reset
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

/*
  base
--------------------------------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

img {
  border-style: none;
  display: block;
  vertical-align: bottom; }

a,
a img,
button {
  transition: .4s; }

a img:hover {
  opacity: .7; }

a {
  text-decoration: none;
  color: #3e3a39; }

/*
a:link {
  color: $color;
  text-decoration: underline;
}

a:visited {
  color: $color;
}

a:hover {
  color: $color;
  text-decoration: none;
}

a:active {
  color: $color;
}
*/
.center {
  text-align: center; }

.t_left {
  text-align: left; }

.t_right {
  text-align: right; }

.mb_10 {
  margin-bottom: 10px; }

.mb_20 {
  margin-bottom: 20px; }

.mb_30 {
  margin-bottom: 30px; }

.mb_40 {
  margin-bottom: 40px; }

.mb_50 {
  margin-bottom: 50px; }

.mb_60 {
  margin-bottom: 60px; }

.mb_70 {
  margin-bottom: 70px; }

.mb_80 {
  margin-bottom: 80px; }

.mb_90 {
  margin-bottom: 90px; }

.mb_100 {
  margin-bottom: 100px; }

.mt_10 {
  margin-top: 10px; }

.mt_20 {
  margin-top: 20px; }

.mt_30 {
  margin-top: 30px; }

.mt_40 {
  margin-top: 40px; }

.mt_50 {
  margin-top: 50px; }

.mt_60 {
  margin-top: 60px; }

.mt_70 {
  margin-top: 70px; }

.mt_80 {
  margin-top: 80px; }

.mt_90 {
  margin-top: 90px; }

.mt_100 {
  margin-top: 100px; }

.mt_130 {
  margin-top: 130px; }

.pb_10 {
  padding-bottom: 10px; }

.pb_20 {
  padding-bottom: 20px; }

.pb_30 {
  padding-bottom: 30px; }

.pb_40 {
  padding-bottom: 40px; }

.pb_50 {
  padding-bottom: 50px; }

.pb_60 {
  padding-bottom: 60px; }

.pb_70 {
  padding-bottom: 70px; }

.pb_80 {
  padding-bottom: 80px; }

.pb_90 {
  padding-bottom: 90px; }

.pb_100 {
  padding-bottom: 100px; }

.pt_10 {
  padding-top: 10px; }

.pt_20 {
  padding-top: 20px; }

.pt_30 {
  padding-top: 30px; }

.pt_40 {
  padding-top: 40px; }

.pt_50 {
  padding-top: 50px; }

.pt_60 {
  padding-top: 60px; }

.pt_70 {
  padding-top: 70px; }

.pt_80 {
  padding-top: 80px; }

.pt_90 {
  padding-top: 90px; }

.pt_100 {
  padding-top: 100px; }

@media (min-width: 768px) {
  /*
 PC common
---------------------------------------- */
  .sp-on {
    display: none; }
  .pc-on {
    display: block; }
  html {
    font-size: 62.5%; }
  body {
    font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
    font-size: 1.6rem;
    line-height: 1.8;
    font-weight: 400;
    color: #333;
    min-width: 1280px;
    text-align: justify; }
  .clearfix::after {
    content: "";
    display: block;
    clear: both; }
  .wrapper {
    width: 1140px;
    margin: auto; }
  header {
    padding-bottom: 14px; }
    header .seo_text {
      font-size: 1.4rem;
      color: #fff;
      background-color: #171c61;
      margin-bottom: 13px; }
    header .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    header .logo {
      padding-left: 50px; }
      header .logo img {
        width: 101px;
        max-width: 100%; }
    header nav {
      width: 900px;
      position: relative; }
      header nav ul {
        font-size: 0; }
        header nav ul li {
          display: inline-block;
          width: calc(98% / 6); }
          header nav ul li a {
            font-size: 20px;
            color: #bfae73;
            text-decoration: none;
            display: block;
            text-align: center;
            padding: 40px 0 32px; }
      header nav #slide-line {
        position: absolute;
        bottom: 0;
        height: 5px;
        background-color: #bfae73;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
  footer {
    margin-top: 180px;
    border-top: 8px solid #171c61;
    border-bottom: 40px solid #171c61;
    padding-top: 30px;
    padding-bottom: 45px; }
    footer .wrapper {
      width: 840px; }
    footer .sns_bnr {
      padding: 20px 0; }
      footer .sns_bnr ul {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    footer nav ul {
      display: flex;
      justify-content: space-between; }
      footer nav ul li {
        padding: 50px 0 70px; }
        footer nav ul li a {
          font-size: 20px;
          color: #bfae73;
          text-decoration: none;
          display: block;
          text-align: center; }
    footer .f_logo {
      text-align: center; }
      footer .f_logo a {
        display: block; }
        footer .f_logo a img {
          margin: auto; }
  #page-top {
    position: fixed;
    bottom: 38px;
    right: 38px; }
    #page-top a {
      text-decoration: none;
      width: 100px;
      display: block; }
  /*
 PC index
---------------------------------------- */
  .home .slider {
    width: 1280px;
    margin: auto; }
    .home .slider button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    .home .slider .slick-dotted.slick-slider {
      margin-bottom: 60px; }
    .home .slider ul.dot-class {
      text-align: center;
      margin-top: 40px; }
      .home .slider ul.dot-class li {
        display: inline-block;
        margin: 0 15px; }
        .home .slider ul.dot-class li button {
          width: 16px;
          position: relative;
          text-indent: -9999px;
          cursor: pointer; }
          .home .slider ul.dot-class li button:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: #c9caca;
            width: 16px;
            height: 16px;
            border-radius: 50%; }
        .home .slider ul.dot-class li.slick-active button:before {
          content: "";
          background-color: #171c61; }
  .home section.news h2 {
    text-align: center;
    font-family: 'Marcellus', serif;
    font-size: 38px;
    position: relative;
    line-height: 1; }
    .home section.news h2:after {
      display: block;
      content: "";
      position: relative;
      margin: 45px auto 0;
      width: 30px;
      height: 1px;
      background-color: #3e3a39; }
  .home section.news ul {
    margin-top: 50px;
    border-top: 1px solid #727171;
    padding-top: 20px; }
    .home section.news ul li {
      font-size: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #727171; }
      .home section.news ul li a {
        text-decoration: none;
        color: #000;
        font-size: 16px; }
      .home section.news ul li p {
        display: inline-block;
        font-size: 16px; }
        .home section.news ul li p.category {
          width: 110px;
          text-align: center;
          padding: 4px 0;
          color: #fff;
          margin-right: 20px;
          line-height: 1; }
          .home section.news ul li p.category.actress {
            background-color: #171c61; }
          .home section.news ul li p.category.singer {
            background-color: #03351b; }
          .home section.news ul li p.category.the_no3 {
            background-color: #89041b; }
        .home section.news ul li p.date {
          margin-right: 20px; }
      .home section.news ul li:not(:last-child) {
        margin-bottom: 20px; }
  .home section.news p.btn {
    margin-top: 50px; }
    .home section.news p.btn a {
      display: block;
      background-color: #171c61;
      color: #fff;
      width: 365px;
      padding: 12px 0;
      font-size: 20px;
      text-align: center;
      text-decoration: none;
      margin: auto; }
  /*
 PC page
---------------------------------------- */
  .page .main_ttl {
    background: #171c61;
    color: #bfae73;
    font-family: 'Marcellus', serif;
    font-size: 48px;
    text-align: center;
    line-height: 1.2;
    padding: 50px 0;
    margin-bottom: 100px; }
  .page .sub_ttl {
    position: relative;
    font-size: 38px;
    font-family: 'Marcellus', serif;
    text-align: center;
    margin-bottom: 75px;
    line-height: 1; }
    .page .sub_ttl span {
      font-size: 28px;
      font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
      display: block;
      padding: 30px 0 0; }
  .page .sub_ttl:after {
    content: '';
    display: block;
    position: relative;
    margin: 50px auto 0;
    width: 30px;
    height: 1px;
    background-color: #3e3a39; }
  .page .sub_ttl_ja {
    font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
    font-size: 28px; }
  .page .min_ttl {
    position: relative;
    font-size: 24px;
    border-bottom: 1px solid #b5b5b6;
    margin-bottom: 84px;
    padding: 0 15px;
    color: #171c61;
    font-weight: bold; }
  .page .min_ttl:before {
    content: "";
    display: block;
    width: 30%;
    border-bottom: 1px solid #171c61;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -1px; }
  .page .section_mb {
    margin-bottom: 140px; }
  .page table {
    width: 100%; }
    .page table th,
    .page table td {
      padding: 5px; }
  .page .two_cont .cont_l {
    margin-right: 40px;
    display: inline-block;
    vertical-align: middle; }
  .page .two_cont .cont_r {
    display: inline-block;
    vertical-align: middle; }
  .page .bg_blue {
    background: #d7ecf7;
    padding: 130px 0; }
  .page .two_cont {
    margin-bottom: 55px; }
  .page a.link_color {
    color: #036eb8;
    text-decoration: underline; }
  /*
 PC profile
---------------------------------------- */
  .profile .wrapper_profile {
    width: 580px;
    margin: 0 auto; }
  .profile .profile-silder img {
    margin: 0 auto; }
  .profile .profile-silder-nav {
    margin: 3.5px 0 0; }
    .profile .profile-silder-nav img {
      height: 34px;
      margin: 0 auto; }
    .profile .profile-silder-nav li {
      display: inline-block;
      margin: 0 10px 0 0; }
  .profile table.profile_tbl th {
    width: 30%;
    vertical-align: top; }
  .profile .cont_sns {
    margin: 0 auto;
    font-size: 0; }
    .profile .cont_sns .two_cont .cont_l {
      width: 20%;
      width: calc(30% - 40px);
      text-align: right; }
      .profile .cont_sns .two_cont .cont_l img {
        width: 68px;
        margin: 0 0 0 auto; }
    .profile .cont_sns .two_cont .cont_r {
      width: 70%;
      font-size: 1.6rem; }
    .profile .cont_sns .two_cont:last-child {
      margin-bottom: 0; }
  .profile .wrapper_link {
    width: 600px;
    margin: 0 auto; }
  .profile footer {
    margin-top: 0; }
  /*
 PC singer
---------------------------------------- */
  .singer .wrapper_singer {
    width: 710px;
    margin: 0 auto; }
  .singer .singer_img {
    margin-bottom: 70px; }
    .singer .singer_img img {
      margin: 0 auto; }
  .singer .singer_img02 {
    margin: 20px 0 10px; }
  .singer .singer_info {
    margin-top: 55px;
    padding-top: 55px;
    border-top: solid 1px #3e3a39; }
  .singer footer {
    margin-top: 0; }
  /*
 PC actress
---------------------------------------- */
  .actress .wrapper_actress {
    width: 720px;
    margin: 0 auto; }
  .actress .actress_ttl {
    margin-top: 40px; }
  .actress .actress_space {
    padding: 70px 0 150px; }
  .actress table {
    font-feature-settings: "palt"; }
    .actress table tr th {
      width: 30%;
      vertical-align: top; }
  .actress ul li {
    font-feature-settings: "palt";
    line-height: 2; }
  .actress .actress_tbl tr th {
    width: 40%; }
  .actress footer {
    margin-top: 0; }
  /*
 PC news
---------------------------------------- */
  .news .news-list li {
    font-size: 0;
    padding: 20px 30px;
    border-bottom: solid 1px #727171; }
    .news .news-list li:first-child {
      border-top: solid 1px #727171; }
    .news .news-list li p {
      display: inline-block;
      font-size: 16px; }
      .news .news-list li p.category {
        width: 110px;
        text-align: center;
        padding: 2px 0;
        color: #fff;
        margin-right: 20px;
        padding: 3px auto; }
        .news .news-list li p.category.actress {
          background-color: #171c61; }
        .news .news-list li p.category.singer {
          background-color: #03351b; }
        .news .news-list li p.category.the_no3 {
          background-color: #89041b; }
      .news .news-list li p.date {
        margin: 0 0 0 15px;
        color: #3E3A39; }
      .news .news-list li p.title {
        margin: 0 0 0 11px;
        color: #3E3A39; }
  .news .pagination {
    font-size: 0;
    width: 380px;
    margin: 66px auto 0; }
    .news .pagination ul {
      margin: 0 auto;
      vertical-align: top;
      text-align: center; }
    .news .pagination li {
      font-size: 16.8px;
      display: inline-block;
      vertical-align: top;
      width: 60px;
      height: 60px;
      background: #fff;
      border-bottom: solid 1.5px #ACA59B;
      margin: 0 0 0 4px;
      text-align: center;
      line-height: 60px; }
      .news .pagination li:first-child {
        margin: 0 0 0 0; }
      .news .pagination li a {
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        color: #CFC3AC; }
      .news .pagination li .current {
        display: block;
        background: #65655E;
        border-color: #464546;
        color: #fff; }
      .news .pagination li .previouspostslink, .news .pagination li .nextpostslink {
        background: #F6F6F6;
        color: #9D9D9E; }
  /*
 PC studio_no3
---------------------------------------- */
  .studio_no3 .main_ttl {
    margin: 0; }
  .studio_no3 footer {
    margin-top: 0; }
  .studio_no3 .photo1 {
    width: 455px;
    margin: 0 auto; }
  .studio_no3 .wrapper-studio-no3 {
    width: 720px;
    margin: 0 auto; }
    .studio_no3 .wrapper-studio-no3 p {
      font-size: 16px;
      color: #3E3A39; }
      .studio_no3 .wrapper-studio-no3 p.margin-top30 {
        margin: 30px 0 0 0; }
      .studio_no3 .wrapper-studio-no3 p.margin-top50 {
        margin: 50px 0 0 0; }
      .studio_no3 .wrapper-studio-no3 p.margin-top80 {
        margin: 80px 0 0 0; }
  .studio_no3 .photo100 {
    width: 100%;
    margin-top: 40px; }
  .studio_no3 h3.margin-top-150 {
    margin-top: 150px; }
  .studio_no3 .cont_dtail {
    margin: 107px auto 0;
    width: 333px;
    font-size: 16px;
    color: #3E3A39; }
    .studio_no3 .cont_dtail img {
      width: 100%;
      margin: 9px 0 0; }
  /*
 PC no3
---------------------------------------- */
  .no3 .wrapper_no3 {
    width: 720px;
    margin: 0 auto; }
    .no3 .wrapper_no3 .section_mb {
      margin-top: 130px; }
  .no3 .no3_img {
    width: 100%; }
  .no3 .cont {
    margin-top: 40px; }
    .no3 .cont .item_ttl {
      font-size: 25px;
      margin-top: 100px;
      margin-bottom: 20px; }
    .no3 .cont .item {
      display: flex;
      justify-content: space-between; }
      .no3 .cont .item .item_img, .no3 .cont .item .item_txt {
        width: 50%; }
      .no3 .cont .item .item_img img {
        max-width: 100%; }
      .no3 .cont .item:last-child {
        margin-bottom: 0; }
  .no3 .cont_dtail {
    margin: 100px auto 0;
    width: 334px; }
  /*
 PC contact
---------------------------------------- */
  .contact .box1 {
    width: 1000px;
    margin: 0 auto;
    background: #D7ECF7;
    box-sizing: border-box;
    padding: 30px 125px; }
    .contact .box1 p {
      font-size: 16px;
      color: #3E3A39; }
    .contact .box1 ul {
      margin: 45px 0 0;
      list-style: disc;
      list-style-position: inside; }
      .contact .box1 ul li {
        font-size: 16px;
        color: #3E3A39; }
  .contact span.req {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    line-height: 1;
    padding: 2px 10px;
    background: #E50012;
    border-radius: 2px; }
  .contact .form_area {
    width: 750px;
    margin: 70px auto 0; }
    .contact .form_area form {
      width: 100%; }
      .contact .form_area form .checkbox-item {
        margin: 25px 0 0; }
        .contact .form_area form .checkbox-item p {
          display: inline;
          color: #3E3A39;
          font-size: 16px; }
      .contact .form_area form input[type="text"] {
        width: 100%;
        font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
        padding: 2px 10px;
        height: 35px;
        border-radius: 5px;
        border: solid 1px #9E9E9F; }
      .contact .form_area form textarea {
        width: 100%;
        font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
        line-height: 1.8;
        padding: 2px 10px;
        border-radius: 5px;
        border: solid 1px #9E9E9F; }
      .contact .form_area form .submit {
        margin: 40px 0 0;
        width: 143px;
        height: 37px;
        border: none;
        font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
        border-radius: 5px;
        background: #171C61;
        font-size: 20px;
        color: #fff; }
      .contact .form_area form table {
        width: 100%;
        font-size: 0; }
        .contact .form_area form table tr {
          display: inline-block;
          width: 100%;
          font-size: 16px; }
          .contact .form_area form table tr th {
            padding: 0;
            display: inline-block;
            width: 100%; }
          .contact .form_area form table tr td {
            padding: 0;
            display: inline-block;
            width: 100%; }
          .contact .form_area form table tr label span {
            margin-left: 15px; }
        .contact .form_area form table .margin-top50 {
          margin-top: 50px; }
        .contact .form_area form table .margin-top20 {
          margin-top: 20px; }
  /*
 404
---------------------------------------- */
  .error404 p {
    text-align: center;
    margin-bottom: 20px; }
  .error404 .btn_link {
    text-align: center; } }

@media (max-width: 767px) {
  /*
 SP common
---------------------------------------- */
  .sp-on {
    display: block; }
  .pc-on {
    display: none; }
  html {
    font-size: 62.5%; }
  body {
    font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
    font-weight: 400;
    color: #333; }
  header {
    position: fixed;
    z-index: 250;
    top: 0;
    left: 0;
    width: 100vw;
    height: 36.5px;
    background: #171C61; }
    header .seo_text {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      text-align: center; }
      header .seo_text h1 {
        position: fixed;
        z-index: 500;
        top: 0;
        width: 100%;
        font-size: 12px;
        color: #fff;
        line-height: 36.5px; }
    header .menu-button {
      position: fixed;
      z-index: 301;
      top: 13px;
      left: 13px;
      width: 15px;
      height: 15px; }
      header .menu-button .nav-toggle {
        width: 100%;
        height: 100%; }
        header .menu-button .nav-toggle span {
          display: block;
          position: absolute;
          height: 1px;
          width: 100%;
          background: #fff;
          left: 0;
          -webkit-transition: .35s ease-in-out;
          transition: .35s ease-in-out; }
          header .menu-button .nav-toggle span:nth-child(1) {
            top: 0; }
          header .menu-button .nav-toggle span:nth-child(2) {
            top: 5px; }
          header .menu-button .nav-toggle span:nth-child(3) {
            top: 10px; }
      header .menu-button .nav-toggle.is-open span:nth-child(1) {
        top: 6px;
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg); }
      header .menu-button .nav-toggle.is-open span:nth-child(2) {
        width: 0;
        left: 50%; }
      header .menu-button .nav-toggle.is-open span:nth-child(3) {
        top: 6px;
        -webkit-transform: rotate(-315deg);
        transform: rotate(-315deg); }
    header #navigation {
      display: none;
      position: fixed;
      z-index: 300;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #000;
      opacity: 0.83; }
      header #navigation ul {
        position: absolute;
        z-index: 350;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }
        header #navigation ul li {
          display: block;
          width: 200px;
          margin: 21px auto 0;
          text-align: center; }
          header #navigation ul li a {
            font-size: 15px;
            color: #fff;
            text-decoration: none; }
        header #navigation ul li:first-child {
          margin: 0 auto 0; }
  footer {
    background: #fff;
    box-sizing: border-box;
    padding: 40px 21px;
    border-top: 4px #171C61 solid;
    border-bottom: 22px #171C61 solid; }
    footer .sns_bnr {
      width: 100%;
      margin: 0 auto; }
      footer .sns_bnr ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center; }
        footer .sns_bnr ul li {
          height: 48px; }
          footer .sns_bnr ul li img {
            width: auto;
            height: 100%; }
        footer .sns_bnr ul li:first-child {
          margin: 0; }
    footer nav {
      margin: 42px auto 0; }
      footer nav a {
        display: block;
        width: 95px;
        height: 28.9px;
        margin: 0 auto;
        font-size: 15px;
        color: #bfae73;
        text-decoration: none;
        text-align: center; }
  #page-top {
    position: fixed;
    bottom: 10px;
    right: 0px; }
    #page-top img {
      width: 60px; }
  .home {
    background: #171C61; }
    .home main {
      background: #fff;
      width: 100vw;
      box-sizing: border-box;
      padding: 20px 8px 87.5px;
      margin: 36.5px 0 0; }
      .home main .logo {
        width: 72px;
        margin: 0 auto; }
        .home main .logo img {
          width: 100%; }
      .home main .slider {
        width: 100%;
        margin: 25px auto 0; }
        .home main .slider ul {
          width: 100%; }
          .home main .slider ul li {
            width: 100%; }
            .home main .slider ul li img {
              width: 100%; }
        .home main .slider .slick-slider {
          margin-bottom: 0; }
        .home main .slider button {
          width: 10px;
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
        .home main .slider ul.dot-class {
          text-align: center;
          margin-top: 10px; }
          .home main .slider ul.dot-class li {
            width: 15px;
            display: inline-block;
            margin: 0 7.5px; }
            .home main .slider ul.dot-class li button {
              position: relative;
              text-indent: -9999px;
              cursor: pointer; }
              .home main .slider ul.dot-class li button:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: #c9caca;
                width: 10px;
                height: 10px;
                border-radius: 50%; }
            .home main .slider ul.dot-class li.slick-active button:before {
              content: "";
              background-color: #171c61; }
      .home main .news {
        margin: 20px auto 0; }
        .home main .news h2 {
          position: relative;
          font-size: 19px;
          text-align: center;
          font-family: 'Marcellus', serif;
          padding: 0 0 21.5px; }
          .home main .news h2::after {
            content: "";
            display: block;
            position: relative;
            margin: 20px auto 0;
            width: 15px;
            height: 1px;
            background-color: #000; }
        .home main .news ul {
          margin: 26.5px auto 0; }
          .home main .news ul li {
            min-height: 70px;
            border-bottom: solid #727171 1px; }
            .home main .news ul li:first-child {
              border-top: solid #727171 1px; }
            .home main .news ul li a {
              display: block;
              position: relative;
              font-size: 12px;
              color: #3E3A39;
              text-decoration: none;
              box-sizing: border-box;
              padding: 43px 13px 16px; }
              .home main .news ul li a .category {
                position: absolute;
                left: 13px;
                top: 15px;
                display: block;
                width: 80px;
                padding: 2px 0;
                font-size: 12px;
                color: #fff;
                text-align: center;
                line-height: 1; }
              .home main .news ul li a .category.actress {
                background: #171C61; }
              .home main .news ul li a .category.singer {
                background: #03351B; }
              .home main .news ul li a .category.the_no3 {
                background: #89041B; }
              .home main .news ul li a .date {
                position: absolute;
                left: 109px;
                top: 13px;
                display: block;
                font-size: 12px; }
        .home main .news .btn {
          display: block;
          width: 184px;
          height: 31px;
          margin: 32px auto 0;
          background: #171C61; }
          .home main .news .btn a {
            display: block;
            widows: 100%;
            height: 100%;
            font-size: 12px;
            color: #fff;
            text-align: center;
            text-decoration: none;
            box-sizing: border-box;
            padding: 5px 0 0; }
  /*
 SP page
---------------------------------------- */
  .page .logo {
    width: 72px;
    margin: 0 auto; }
    .page .logo img {
      width: 100%;
      margin: 0 auto;
      padding: 55px 0 20px; }
  .page .main_ttl {
    background: #171c61;
    color: #bfae73;
    font-family: 'Marcellus', serif;
    font-size: 24px;
    text-align: center;
    line-height: 1.2;
    padding: 40px 0;
    margin-bottom: 60px; }
  .page .sub_ttl {
    position: relative;
    font-size: 19px;
    font-family: 'Marcellus', serif;
    text-align: center;
    margin-bottom: 50px; }
    .page .sub_ttl span {
      font-size: 1.5rem;
      font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
      display: block;
      padding: 10px 0 0; }
  .page main .wrapper {
    padding: 0 10px; }
  .page .sub_ttl:after {
    content: '';
    display: block;
    position: relative;
    margin: 20px auto 0;
    width: 15px;
    height: 1px;
    background-color: #000; }
  .page .sub_ttl_ja {
    font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif; }
  .page .min_ttl {
    position: relative;
    font-size: 1.6rem;
    border-bottom: 1px solid #b5b5b6;
    margin-bottom: 40px;
    color: #171c61;
    font-weight: bold; }
  .page .min_ttl:before {
    content: "";
    display: block;
    width: 30%;
    border-bottom: 1px solid #171c61;
    position: absolute;
    bottom: 0;
    margin-bottom: -1px; }
  .page .section_mb {
    margin-bottom: 100px; }
  .page table {
    width: 100%; }
    .page table th {
      width: 40%; }
    .page table th,
    .page table td {
      vertical-align: top;
      padding: 5px; }
  .page .two_cont .cont_l {
    margin-right: 0;
    margin-bottom: 20px; }
    .page .two_cont .cont_l img {
      margin: 0 auto; }
  .page .bg_blue {
    background: #d7ecf7;
    padding: 80px 0; }
  .page .two_cont {
    margin-bottom: 55px;
    text-align: center; }
  /*
 SP profile
---------------------------------------- */
  .profile .wrapper_profile {
    width: 100%;
    margin: 0 auto; }
  .profile .profile-silder img {
    margin: 0 auto;
    width: 100%; }
  .profile .profile-silder-nav {
    margin: 3.5px 0 0; }
    .profile .profile-silder-nav img {
      height: 34px;
      margin: 0 auto; }
    .profile .profile-silder-nav li {
      display: inline-block;
      margin: 0 10px 0 0; }
  .profile table.profile_tbl th {
    width: 25%; }
  .profile .cont_sns .two_cont .cont_l {
    margin-right: 0;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px; }
  .profile .cont_sns .two_cont .cont_r {
    display: inline-block;
    vertical-align: middle; }
  .profile .profile_sns img {
    width: 87%; }
  .profile .cont_sns {
    width: 100%;
    margin: 0 auto; }
    .profile .cont_sns .two_cont:last-child {
      margin-bottom: 0; }
  .profile .wrapper_link {
    width: 100%;
    margin: 0 auto; }
    .profile .wrapper_link .two_cont .cont_l {
      margin-right: 0; }
  .profile footer {
    margin-top: 0; }
  /*
 PC singer
---------------------------------------- */
  .singer .wrapper_singer {
    width: 100%;
    margin: 0 auto; }
  .singer .singer_img {
    margin-bottom: 70px; }
    .singer .singer_img img {
      width: 60%;
      margin: 0 auto; }
  .singer .singer_img02 {
    margin: 20px 0; }
    .singer .singer_img02 img {
      margin: 0 auto; }
  .singer .singer_info {
    margin-top: 55px;
    padding-top: 55px;
    border-top: solid 1px #3e3a39; }
  .singer a.link_color {
    color: #036eb8;
    text-decoration: underline; }
  .singer footer {
    margin-top: 0; }
  /*
 PC actress
---------------------------------------- */
  .actress .wrapper_actress {
    width: 100%;
    margin: 0 auto; }
  .actress .actress_ttl {
    margin-top: 40px; }
  .actress .actress_space {
    padding: 80px 0; }
  .actress table {
    font-feature-settings: "palt"; }
  .actress ul li {
    font-feature-settings: "palt";
    line-height: 2; }
  .actress .actress_space_first {
    padding: 0 0 80px; }
  .actress footer {
    margin-top: 0; }
  /*
 PC news
---------------------------------------- */
  .news .news-list {
    margin: 26.5px auto 0; }
    .news .news-list li {
      min-height: 70px;
      border-bottom: solid #727171 1px;
      position: relative; }
      .news .news-list li:first-child {
        border-top: solid #727171 1px; }
      .news .news-list li a {
        display: block;
        position: relative;
        font-size: 12px;
        color: #3E3A39;
        text-decoration: none;
        box-sizing: border-box;
        padding: 43px 13px 16px; }
      .news .news-list li .category {
        position: absolute;
        left: 13px;
        top: 15px;
        display: block;
        width: 80px;
        padding: 2px 0;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 1; }
      .news .news-list li .category.actress {
        background: #171C61; }
      .news .news-list li .category.singer {
        background: #03351B; }
      .news .news-list li .category.the_no3 {
        background: #89041B; }
      .news .news-list li .date {
        position: absolute;
        left: 109px;
        top: 13px;
        display: block;
        font-size: 12px; }
  .news .pagination {
    font-size: 0;
    max-width: 380px;
    margin: 66px auto 70px;
    text-align: center; }
    .news .pagination ul {
      vertical-align: top;
      text-align: center;
      margin: 0 auto; }
    .news .pagination li {
      vertical-align: top;
      font-size: 1.2rem;
      display: inline-block;
      max-width: 40px;
      height: 40px;
      width: 40px;
      background: #fff;
      border-bottom: solid 1.5px #ACA59B;
      margin: 0 0 0 4px;
      text-align: center;
      line-height: 40px; }
      .news .pagination li:first-child {
        margin: 0 0 0 0; }
      .news .pagination li a {
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        color: #CFC3AC; }
      .news .pagination li .current {
        display: block;
        background: #65655E;
        border-color: #464546;
        color: #fff; }
      .news .pagination li .previouspostslink, .news .pagination li .nextpostslink {
        background: #F6F6F6;
        color: #9D9D9E; }
  /*
 PC studio_no3
---------------------------------------- */
  .studio_no3 .main_ttl {
    margin: 0; }
  .studio_no3 .photo1 {
    width: 60%;
    margin: 0 auto; }
  .studio_no3 .wrapper-studio-no3 {
    width: 100%;
    margin: 0 auto; }
    .studio_no3 .wrapper-studio-no3 p {
      color: #3E3A39; }
      .studio_no3 .wrapper-studio-no3 p.margin-top30 {
        margin: 30px 0 0 0; }
      .studio_no3 .wrapper-studio-no3 p.margin-top50 {
        margin: 50px 0 0 0; }
      .studio_no3 .wrapper-studio-no3 p.margin-top80 {
        margin: 80px 0 0 0; }
  .studio_no3 .photo100 {
    width: 100%;
    margin-top: 40px; }
  .studio_no3 h3.margin-top-150 {
    margin-top: 150px; }
  .studio_no3 .cont_dtail {
    margin: 107px auto 0;
    width: 75%;
    font-size: 14px;
    color: #3E3A39; }
    .studio_no3 .cont_dtail img {
      width: 100%;
      margin: 9px 0 0; }
  /*
 PC no3
---------------------------------------- */
  .no3 .wrapper_no3 {
    width: 100%;
    margin: 0 auto; }
    .no3 .wrapper_no3 .section_mb {
      margin-top: 80px; }
  .no3 .no3_img {
    width: 100%; }
    .no3 .no3_img img {
      width: 100%; }
  .no3 .cont {
    margin-top: 20px; }
    .no3 .cont .item_ttl {
      font-size: 19px;
      margin-top: 70px;
      margin-bottom: 20px;
      text-align: center; }
    .no3 .cont .item {
      display: block;
      margin-bottom: 40px; }
      .no3 .cont .item .item_txt {
        margin-bottom: 20px; }
      .no3 .cont .item .item_img img {
        max-width: 100%; }
      .no3 .cont .item:last-child {
        margin-bottom: 0; }
  .no3 .cont_dtail {
    margin: 50px auto 0;
    width: 75%; }
    .no3 .cont_dtail img {
      width: 100%; }
  /*
 SP contact
---------------------------------------- */
  .contact .box1 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background: #D7ECF7;
    box-sizing: border-box;
    padding: 30px 10px; }
    .contact .box1 p {
      color: #3E3A39; }
    .contact .box1 ul {
      margin: 45px 0 0;
      list-style: disc;
      list-style-position: inside; }
      .contact .box1 ul li {
        color: #3E3A39; }
  .contact span.req {
    font-size: 1rem;
    color: #fff;
    padding: 2px 10px;
    background: #E50012;
    border-radius: 2px;
    margin-right: 5px; }
  .contact .form_area {
    width: 100%;
    margin: 70px auto; }
    .contact .form_area form {
      width: 100%; }
      .contact .form_area form .checkbox-item {
        margin: 15px 0 0; }
        .contact .form_area form .checkbox-item p {
          display: inline;
          color: #3E3A39; }
      .contact .form_area form input[type="text"] {
        width: 100%;
        height: 35px;
        font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
        padding: 2px 2%;
        border-radius: 5px;
        border: solid 1px #9E9E9F; }
      .contact .form_area form textarea {
        width: 100%;
        font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
        padding: 2px 2%;
        line-height: 1.8;
        border-radius: 5px;
        border: solid 1px #9E9E9F; }
      .contact .form_area form .submit {
        font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
        margin: 40px 0 0;
        width: 100%;
        height: 37px;
        border-radius: 5px;
        background: #171C61;
        font-size: 1.5rem;
        color: #fff;
        border: none; }
      .contact .form_area form table {
        width: 100%;
        font-size: 0; }
        .contact .form_area form table tr {
          display: inline-block;
          width: 100%;
          font-size: 1.2rem; }
          .contact .form_area form table tr th {
            padding: 0;
            display: inline-block;
            width: 100%;
            margin-bottom: 10px; }
          .contact .form_area form table tr td {
            padding: 0;
            display: inline-block;
            width: 100%; }
          .contact .form_area form table tr label span {
            margin: 0 0 0 15px; }
        .contact .form_area form table .margin-top50 {
          margin-top: 50px; }
        .contact .form_area form table .margin-top20 {
          margin-top: 20px; }
  /*
 404
---------------------------------------- */
  .error404 p {
    text-align: center;
    margin-bottom: 20px; }
  .error404 .btn_link {
    text-align: center;
    margin-bottom: 70px; } }
