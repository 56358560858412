/*
  base
--------------------------------------------- */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  border-style: none;
  display: block;
  vertical-align: bottom;
}

a,
a img,
button {
  transition: .4s;
}

a img:hover {
  opacity: .7;
}
a{
  text-decoration: none;
  color: #3e3a39;
}
/*
a:link {
  color: $color;
  text-decoration: underline;
}

a:visited {
  color: $color;
}

a:hover {
  color: $color;
  text-decoration: none;
}

a:active {
  color: $color;
}
*/
.center {
  text-align: center;
}
.t_left {
  text-align: left;
}
.t_right {
  text-align: right;
}

.mb_10 {
  margin-bottom: 10px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_30 {
  margin-bottom: 30px;
}
.mb_40 {
  margin-bottom: 40px;
}
.mb_50 {
  margin-bottom: 50px;
}
.mb_60 {
  margin-bottom: 60px;
}
.mb_70 {
  margin-bottom: 70px;
}
.mb_80 {
  margin-bottom: 80px;
}
.mb_90 {
  margin-bottom: 90px;
}
.mb_100 {
  margin-bottom: 100px;
}

.mt_10 {
  margin-top: 10px;
}
.mt_20 {
  margin-top: 20px;
}
.mt_30 {
  margin-top: 30px;
}
.mt_40 {
  margin-top: 40px;
}
.mt_50 {
  margin-top: 50px;
}
.mt_60 {
  margin-top: 60px;
}
.mt_70 {
  margin-top: 70px;
}
.mt_80 {
  margin-top: 80px;
}
.mt_90 {
  margin-top: 90px;
}
.mt_100 {
  margin-top: 100px;
}

.mt_130 {
  margin-top: 130px;
}

.pb_10 {
  padding-bottom: 10px;
}
.pb_20 {
  padding-bottom: 20px;
}
.pb_30 {
  padding-bottom: 30px;
}
.pb_40 {
  padding-bottom: 40px;
}
.pb_50 {
  padding-bottom: 50px;
}
.pb_60 {
  padding-bottom: 60px;
}
.pb_70 {
  padding-bottom: 70px;
}
.pb_80 {
  padding-bottom: 80px;
}
.pb_90 {
  padding-bottom: 90px;
}
.pb_100 {
  padding-bottom: 100px;
}

.pt_10 {
  padding-top: 10px;
}
.pt_20 {
  padding-top: 20px;
}
.pt_30 {
  padding-top: 30px;
}
.pt_40 {
  padding-top: 40px;
}
.pt_50 {
  padding-top: 50px;
}
.pt_60 {
  padding-top: 60px;
}
.pt_70 {
  padding-top: 70px;
}
.pt_80 {
  padding-top: 80px;
}
.pt_90 {
  padding-top: 90px;
}
.pt_100 {
  padding-top: 100px;
}