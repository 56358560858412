/*
 SP common
---------------------------------------- */

.sp-on {
  display: block;
}

.pc-on {
  display: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $font-family;
  font-size: $fs-12;
  line-height: $line-height;
  font-weight: 400;
  color: $color;
}

header {
  position: fixed;
  z-index: 250;
  top: 0;
  left: 0;
  width: 100vw;
  height: 36.5px;
  background: #171C61;
  .seo_text {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    h1 {
      position: fixed;
      z-index: 500;
      top: 0;
      width: 100%;
      font-size: 12px;
      color: #fff;
      line-height: 36.5px;
    }
  }
  .menu-button {
    position: fixed;
    z-index: 301;
    top: 13px;
    left: 13px;
    width: 15px;
    height: 15px;
    .nav-toggle {
      width: 100%;
      height: 100%;
      span {
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background: #fff;
        left: 0;
        -webkit-transition: .35s ease-in-out;
        transition: .35s ease-in-out;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 5px;
        }
        &:nth-child(3) {
          top: 10px;
        }
      }
    }
    .nav-toggle.is-open {
      span {
        &:nth-child(1) {
          top: 6px;
          -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
        }
        &:nth-child(2) {
          width: 0;
          left: 50%;
        }
        &:nth-child(3) {
          top: 6px;
          -webkit-transform: rotate(-315deg);
          transform: rotate(-315deg);
        }
      }
    }
  }
  #navigation {
    display: none;
    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.83;
    ul {
      position: absolute;
      z-index: 350;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      li {
        display: block;
        width: 200px;
        margin: 21px auto 0;
        text-align: center;
        a {
          font-size: 15px;
          color: #fff;
          text-decoration: none;
        }
      }
      li:first-child {
        margin: 0 auto 0;
      }
    }
  }
}

footer {
  background: #fff;
  box-sizing: border-box;
  padding: 40px 21px;
  border-top: 4px #171C61 solid;
  border-bottom: 22px #171C61 solid;
  .sns_bnr {
    width: 100%;
    margin: 0 auto;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      li {
        height: 48px;
        img {
          width: auto;
          height: 100%;
        }
      }
      li:first-child {
        margin: 0;
      }
    }
  }
  nav {
    margin: 42px auto 0;
    a {
      display: block;
      width: 95px;
      height: 28.9px;
      margin: 0 auto;
      font-size: 15px;
      color: #bfae73;
      text-decoration: none;
      text-align: center;
    }
  }
}

#page-top {
  position: fixed;
  bottom: 10px;
  right: 0px;
  img {
    width: 60px;
  }
}
