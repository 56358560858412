// ----- fonts

$font-family: "Noto Sans CJK JP", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;

$font-size: 1.6rem;
$line-height: 1.8;

$fs-10: 1rem;
$fs-11: 1.1rem;
$fs-12: 1.2rem;
$fs-13: 1.3rem;
$fs-14: 1.4rem;
$fs-15: 1.5rem;
$fs-16: 1.6rem;
$fs-17: 1.7rem;
$fs-18: 1.8rem;
$fs-19: 1.9rem;
$fs-20: 2rem;
$fs-21: 2.1rem;
$fs-22: 2.2rem;
$fs-23: 2.3rem;
$fs-24: 2.4rem;
$fs-25: 2.5rem;
$fs-28: 2.8rem;
$fs-32: 3.2rem;
$fs-48: 4.8rem;

// ----- colors

$color: #333;
$cl-purple: #9d56e4;
$cl-lightBlue: #778fce;
$cl-blue: #110051;
$cl-lightGreen: #32cca6;
$cl-green: #02c255;
$cl-orange: #ff9537;
$cl-yellow: #ffd200;
$cl-red: #f85550;
$cl-lightGray: #666;
$cl-gray: #8a8a8a;
$cl-lightPurple: #b7b2ca;
$cl-grey: #bfbfbf;
$cl-lightGrey: #f4f3f7;

// ----- layout

$max-width: 1340px;
$min-width: 1036px;