@charset "UTF-8";

@import "component/config";
@import "component/mixin";

@import "component/reset";
@import "component/base";

@media (min-width: 768px) {
	@import "parts/pc/pc-common";
	@import "parts/pc/pc-index";
  @import "parts/pc/pc-page";
}

@media (max-width: 767px) {
	@import "parts/sp/sp-common";
	@import "parts/sp/sp-index";
  @import "parts/sp/sp-page";
}