.home {
  background: #171C61;
  main {
    background: #fff;
    width: 100vw;
    box-sizing: border-box;
    padding: 20px 8px 87.5px;
    margin: 36.5px 0 0;
    .logo {
      width: 72px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .slider {
      width: 100%;
      margin: 25px auto 0;
      ul {
        width: 100%;
        li {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .slick-slider {
        margin-bottom: 0;
      }
      button {
        width: 10px;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      ul.dot-class {
        text-align: center;
        margin-top: 10px;
        li {
          width: 15px;
          display: inline-block;
          margin: 0 (15px/2);
          button {
            position: relative;
            text-indent: -9999px;
            cursor: pointer;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              background-color: #c9caca;
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
          }
          &.slick-active {
            button:before {
              content: "";
              background-color: #171c61;
            }
          }
        }
      }
    }
    .news {
      margin: 20px auto 0;
      h2 {
        position: relative;
        font-size: 19px;
        text-align: center;
        font-family: 'Marcellus', serif;
        padding: 0 0 21.5px;
        &::after {
          content: "";
          display: block;
          position: relative;
          margin: 20px auto 0;
          width: 15px;
          height: 1px;
          background-color: #000;
        }
      }
      ul {
        margin: 26.5px auto 0;
        li {
          min-height: 70px;
          border-bottom: solid #727171 1px;
          &:first-child {
            border-top: solid #727171 1px;
          }
          a {
            display: block;
            position: relative;
            font-size: 12px;
            color: #3E3A39;
            text-decoration: none;
            box-sizing: border-box;
            padding: 43px 13px 16px;
            .category {
              position: absolute;
              left: 13px;
              top: 15px;
              display: block;
              width: 80px;
              padding: 2px 0;
              font-size: 12px;
              color: #fff;
              text-align: center;
              line-height: 1;
            }
            .category.actress {
              background: #171C61;
            }
            .category.singer {
              background: #03351B;
            }
            .category.the_no3 {
              background: #89041B;
            }
            .date {
              position: absolute;
              left: 109px;
              top: 13px;
              display: block;
              font-size: 12px;
            }
          }
        }
      }
      .btn {
        display: block;
        width: 184px;
        height: 31px;
        margin: 32px auto 0;
        background: #171C61;
        a {
          display: block;
          widows: 100%;
          height: 100%;
          font-size: 12px;
          color: #fff;
          text-align: center;
          text-decoration: none;
          box-sizing: border-box;
          padding: 5px 0 0;
        }
      }
    }
  }
}
