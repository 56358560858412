/*
 PC index
---------------------------------------- */

.home {
  .slider {
    width: 1280px;
    margin: auto;
    button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .slick-dotted.slick-slider {
      margin-bottom: 60px;
    }
    ul.dot-class {
      text-align: center;
      margin-top: 40px;
      li {
        display: inline-block;
        margin: 0 15px;
        button {
          width: 16px;
          position: relative;
          text-indent: -9999px;
          cursor: pointer;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: #c9caca;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button:before {
            content: "";
            background-color: #171c61;
          }
        }
      }
    }
  }
  section.news {
    h2 {
      text-align: center;
      font-family: 'Marcellus', serif;
      font-size: 38px;
      position: relative;
      line-height: 1;
      &:after {
        display: block;
        content: "";
        position: relative;
        margin: 45px auto 0;
        width: 30px;
        height: 1px;
        background-color: #3e3a39;
      }
    }
    ul {
      margin-top: 50px;
      border-top: 1px solid #727171;
      padding-top: 20px;
      li {
        font-size: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid #727171;
        a {
          text-decoration: none;
          color: #000;
          font-size: 16px;
        }
        p {
          display: inline-block;
          font-size: 16px;
          &.category {
            width: 110px;
            text-align: center;
            padding: 4px 0;
            color: #fff;
            margin-right: 20px;
            line-height: 1;
            &.actress {
              background-color: #171c61;
            }
            &.singer {
              background-color: #03351b;
            }
            &.the_no3 {
              background-color: #89041b;
            }
          }
          &.date {
            margin-right: 20px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    p.btn {
      margin-top: 50px;
      a {
        display: block;
        background-color: #171c61;
        color: #fff;
        width: 365px;
        padding: 12px 0;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        margin: auto;
      }
    }
  }
}
