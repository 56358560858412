/*
 PC page
---------------------------------------- */

.page {
  .main_ttl {
    background: #171c61;
    color: #bfae73;
    font-family: 'Marcellus', serif;
    font-size: 48px;
    text-align: center;
    line-height: 1.2;
    padding: 50px 0;
    margin-bottom: 100px;
  }
  .sub_ttl {
    position: relative;
    font-size: 38px;
    font-family: 'Marcellus', serif;
    text-align: center;
    margin-bottom: 75px;
    line-height: 1;
    span {
      font-size: 28px;
      font-family: $font-family;
      display: block;
      padding: 30px 0 0;
    }
  }
  .sub_ttl:after {
    content: '';
    display: block;
    position: relative;
    margin: 50px auto 0;
    width: 30px;
    height: 1px;
    background-color: #3e3a39;
  }
  .sub_ttl_ja {
    font-family: $font-family;
    font-size: 28px;
  }
  .min_ttl {
    position: relative;
    font-size: 24px;
    border-bottom: 1px solid #b5b5b6;
    margin-bottom: 84px;
    padding: 0 15px;
    color: #171c61;
    font-weight: bold;
  }
  .min_ttl:before {
    content: "";
    display: block;
    width: 30%;
    border-bottom: 1px solid #171c61;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -1px;
  }
  .section_mb {
    margin-bottom: 140px;
  }
  table {
    width: 100%;
    th,
    td {
      padding: 5px;
    }
  }
  .two_cont {
    .cont_l {
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
    }
    .cont_r {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .bg_blue {
    background: #d7ecf7;
    padding: 130px 0;
  }
  .two_cont {
    margin-bottom: 55px;
  }
  a.link_color {
    color: #036eb8;
    text-decoration: underline;
  }
}

/*
 PC profile
---------------------------------------- */

.profile {
  .wrapper_profile {
    width: 580px;
    margin: 0 auto;
  }
  .profile-silder {
    img {
      margin: 0 auto;
    }
  }

  .profile-silder-nav {
    margin: 3.5px 0 0;
    img {
      height: 34px;
      margin: 0 auto;
    }
    li {
      display: inline-block;
      margin: 0 10px 0 0;
    }
  }
  table.profile_tbl {
    th {
      width: 30%;
      vertical-align: top;
    }
  }

  .cont_sns {
    margin: 0 auto;
    font-size: 0;
    .two_cont {
      .cont_l {
        width: 20%;
        width: calc(30% - 40px);
        text-align: right;
        img {
          width: 68px;
          margin: 0 0 0 auto;
        }
      }
      .cont_r {
        width: 70%;
        font-size: $font-size;
      }
    }
    .two_cont:last-child {
      margin-bottom: 0;
    }
  }
  .wrapper_link {
    width: 600px;
    margin: 0 auto;
  }
  footer {
    margin-top: 0;
  }
}


/*
 PC singer
---------------------------------------- */

.singer {
  .wrapper_singer {
    width: 710px;
    margin: 0 auto;
  }
  .singer_img {
    margin-bottom: 70px;
    img {
      margin: 0 auto;
    }
  }
  .singer_img02 {
    margin: 20px 0 10px;
  }
  .singer_info {
    margin-top: 55px;
    padding-top: 55px;
    border-top: solid 1px #3e3a39;
  }
  footer {
    margin-top: 0;
  }
}

/*
 PC actress
---------------------------------------- */

.actress {
  .wrapper_actress {
    width: 720px;
    margin: 0 auto;
  }
  .actress_ttl {
    margin-top: 40px;
  }
  .actress_space {
    padding: 70px 0 150px;
  }
  table {
    font-feature-settings : "palt";
    tr {
      th {
        width: 30%;
        vertical-align: top;
      }
    }
  }
  ul {
    li {
      font-feature-settings : "palt";
      line-height: 2;
    }
  }
  .actress_tbl {
    tr {
      th {
        width: 40%;
      }
    }
  }
  footer {
    margin-top: 0;
  }
}

/*
 PC news
---------------------------------------- */

.news {
  .news-list {
    li {
      font-size: 0;
      padding: 20px 30px;
      border-bottom: solid 1px #727171;
      &:first-child {
        border-top: solid 1px #727171;
      }
      p {
        display: inline-block;
        font-size: 16px;
        &.category {
          width: 110px;
          text-align: center;
          padding: 2px 0;
          color: #fff;
          margin-right: 20px;
          padding: 3px auto;
          &.actress {
            background-color: #171c61;
          }
          &.singer {
            background-color: #03351b;
          }
          &.the_no3 {
            background-color: #89041b;
          }
        }
        &.date {
          margin: 0 0 0 15px;
          color: #3E3A39;
        }
        &.title {
          margin: 0 0 0 11px;
          color: #3E3A39;
        }
      }
    }
  }
  .pagination {
    font-size: 0;
    width: 60*6+4*5px;
    margin: 66px auto 0;
    ul {
      margin: 0 auto;
      vertical-align: top;
      text-align: center;
    }
    li {
      font-size: 16.8px;
      display: inline-block;
      vertical-align: top;
      width: 60px;
      height: 60px;
      background: #fff;
      border-bottom: solid 1.5px #ACA59B;
      margin: 0 0 0 4px;
      text-align: center;
      line-height: 60px;
      &:first-child {
        margin: 0 0 0 0;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        color: #CFC3AC;
        &:hover {}
      }
      .current {
        display: block;
        background: #65655E;
        border-color: #464546;
        color: #fff;
      }
      .previouspostslink, .nextpostslink {
        background: #F6F6F6;
        color: #9D9D9E;
      }
    }
  }
}

/*
 PC studio_no3
---------------------------------------- */

.studio_no3 {
  .main_ttl {
    margin: 0;
  }
  footer {
    margin-top: 0;
  }
  .photo1 {
    width: 455px;
    margin: 0 auto;
  }
  .wrapper-studio-no3 {
    width: 720px;
    margin: 0 auto;
    p {
      font-size: 16px;
      color: #3E3A39;
      &.margin-top30 {
        margin: 30px 0 0 0;
      }
      &.margin-top50 {
        margin: 50px 0 0 0;
      }
      &.margin-top80 {
        margin: 80px 0 0 0;
      }
    }
  }
  .photo100 {
    width: 100%;
    margin-top: 40px;
  }
  h3.margin-top-150 {
    margin-top: 150px;
  }
  .cont_dtail {
    margin: 107px auto 0;
    width: 333px;
    font-size: 16px;
    color: #3E3A39;
    img {
      width: 100%;
      margin: 9px 0 0;
    }
  }
}

/*
 PC no3
---------------------------------------- */

.no3 {
  .wrapper_no3 {
    width: 720px;
    margin: 0 auto;
    .section_mb {
      margin-top: 130px;
    }
  }
  .no3_img {
    width: 100%;
  }
  .cont {
    margin-top: 40px;
    .item_ttl{
      font-size: 25px;
      margin-top: 100px;
      margin-bottom: 20px;
    }
    .item{
      display: flex;
      justify-content: space-between;
      .item_img,.item_txt{
        width: 50%;
      }
      .item_img{
        img{
          max-width: 100%;
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .cont_dtail {
    margin: 100px auto 0;
    width: 334px;
  }
}

/*
 PC contact
---------------------------------------- */

.contact {
  .box1 {
    width: 1000px;
    margin: 0 auto;
    background: #D7ECF7;
    box-sizing: border-box;
    padding: 30px 125px;
    p {
      font-size: 16px;
      color: #3E3A39;
    }
    ul {
      margin: 45px 0 0;
      list-style: disc;
      list-style-position: inside;
      li {
        font-size: 16px;
        color: #3E3A39;
      }
    }
  }
  span.req {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    line-height: 1;
    padding: 2px 10px;
    background: #E50012;
    border-radius: 2px;
  }
  .form_area {
    width: 750px;
    margin: 70px auto 0;
    form {
      width: 100%;
      .checkbox-item {
        margin: 25px 0 0;
        input[type="checkbox"] {}
        p {
          display: inline;
          color: #3E3A39;
          font-size: 16px;
        }
      }
      input[type="text"] {
        width: 100%;
        font-family: $font-family;
        padding: 2px 10px;
        height: 35px;
        border-radius: 5px;
        border: solid 1px #9E9E9F;
      }
      textarea {
        width: 100%;
        font-family: $font-family;
        line-height: $line-height;
        padding: 2px 10px;
        border-radius: 5px;
        border: solid 1px #9E9E9F;
      }
      .submit {
        margin: 40px 0 0;
        width: 143px;
        height: 37px;
        border: none;
        font-family: $font-family;
        border-radius: 5px;
        background: #171C61;
        font-size: 20px;
        color: #fff;
      }
      table {
        width: 100%;
        font-size: 0;
        tr {
          display: inline-block;
          width: 100%;
          font-size: 16px;
          th {
            padding: 0;
            display: inline-block;
            width: 100%;
          }
          td {
            padding: 0;
            display: inline-block;
            width: 100%;
          }
          label {
            span {
              margin-left: 15px;
            }
          }
        }
        .margin-top50 {
          margin-top: 50px;
        }
        .margin-top20 {
          margin-top: 20px;
        }
      }
    }
  }
}

/*
 404
---------------------------------------- */
.error404 {
  p {
    text-align: center;
    margin-bottom: 20px;
  }
  .btn_link {
    text-align: center;
  }
}